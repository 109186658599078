export const validateEmail = (email) => {
  email = email.trim();
  if (email.length === 0) {
    return "Esse campo é obrigatório";
  }
  let isValid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  if (!isValid) {
    return "Por favor, insira um e-mail válido";
  }
  return "";
}

export const validatePhone = (phone) => {
  phone = phone.trim()
  if (phone.length === 0) {
    return "Esse campo é obrigatório"
  }
  let isValid = String(phone)
    .toLowerCase()
    .match(
      /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/
    );
  if (!isValid) {
    return "Por favor, insira um telefone válido"
  }
  return ''
}

export const validateName = (text) => {
  text = text.trim()
  if (text.length === 0) {
    return "Esse campo é obrigatório"
  }
  let parts = text.split(" ")
  let validParts = 0
  for (let part of parts) {
    if (part.trim().length > 0) {
      validParts++
    }
  }
  if (validParts < 2) {
    return "Por favor, insira seu nome completo"
  }

  return ""
}

export const validateTaxpayerID = (text) => {
  if (/^\d+$/.test(text) && (text.length === 11 || text.length === 14)) {
    return ""
  } else {
    return "Deve ser um CPF ou CNPJ válido sem traços ou pontos"
  }
}

export const validateCEP = (cep) => {
  if (/^\d{8}$/.test(cep)) {
    return '';
  } else {
    return "O CEP deve conter exatamente 8 dígitos numéricos";
  }
};

export const validateCardNumber = (number) => {
  number = number.trim()
  if (number.length === 0) {
    return "Esse campo é obrigatório"
  }
  let isValid = String(number)
    .toLowerCase()
    .match(
      /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
    );
  if (!isValid) {
    return "Por favor, insira um número de cartão válido"
  }
  return ""
}
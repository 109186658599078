import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Layout,
  Modal,
  Result,
  Select,
  Skeleton,
  Spin,
  Table,
  notification
} from "antd";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiAccessAccountList, apiAccessList, apiCreateAccessAccount, apiDeleteAccessAccount } from '../../../../../api/access';
import { formatDate } from "../../../../../utils/format";
import { useIsMobileHook } from "../../../../../utils/WindowHooks";
import AccessCreateModal from "../../../access/AccessCreate/AccessCreate";
import './AccessAccount.scss';

const { Content } = Layout;

const AccessAccount = ({ dataInfo }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const isMobile = useIsMobileHook(1600);
  const [loading, setLoading] = useState(true);
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleRowClick = (record) => {
    const id = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];
    const index = newExpandedRowKeys.indexOf(id);
    if (index > -1) {
      newExpandedRowKeys.splice(index, 1);
    } else {
      newExpandedRowKeys.push(id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const getAccesses = async () => {
    setLoading(true);
    try {
      const json = await apiAccessAccountList(dataInfo.id);
      setData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar os acessos.",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar os acessos. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("404");
        notification.error("Erro ao buscar os acessos.");
      }
    }
  };

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  useEffect(() => {
    getAccesses();
  }, []);

  const columns = [
    {
      title: "Nome",
      dataIndex: ["user", "username"],
      key: "user_name",
      render: (username) => (
        <p dangerouslySetInnerHTML={{ __html: username }} />
      ),
      responsive: ["xl"],
    },
    {
      title: "Email",
      dataIndex: ["user", "email"],
      key: "user_email",
      render: (user_email) => (
        <p className="truncate-text" dangerouslySetInnerHTML={{ __html: user_email }} />
      ),
    },
    {
      title: "Data de Entrada",
      dataIndex: "created_at",
      key: "date_joined",
      render: (date_joined) => (
        <p dangerouslySetInnerHTML={{ __html: new Date(date_joined).toLocaleDateString() }} />
      ),
      responsive: ["xl"],
    },
    {
      title: "Cargo",
      dataIndex: "is_superuser",
      key: "is_superuser",
      render: (is_superuser) => (
        <p style={{ textTransform: "capitalize" }} className={`badge ${is_superuser ? "badge-success" : "badge-error"}`}>
          {is_superuser ? "Administrador" : "Vendedor"}
        </p>
      ),
      responsive: ["sm"],
    },
    {
      title: "Detalhes",
      dataIndex: "",
      render: (record) => (
        <Link to={`/admin/acessos/${record.user.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      ),
      key: "info",
      responsive: ["sm"],
    },
    {
      title: "Excluir",
      dataIndex: "",
      render: (record) => (
        <DeleteOutlined onClick={() => confirmDelete(record)} style={{ cursor: "pointer", height: "100%" }} />
      ),
      key: "info",
      responsive: ["sm"],
    },
  ];

  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  const confirmDelete = (record) => {
    Modal.confirm({
      title: 'Tem certeza que deseja deletar?',
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Deletar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => handleDelete(dataInfo.id, record?.user?.id),
    });
  };

  const handleDelete = async (id, idUser) => {
    try {
      await apiDeleteAccessAccount(idUser, id).then(json => {
        notification.success({
          message: "Acesso deletado com sucesso",
          description: "Acesso deletado com sucesso",
          placement: "topRight",
        });
        setIsModalVisible(false);
        setIsSecondModalVisible(false)
        getAccesses();
      })
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro ao deletar o acesso.",
        placement: "topRight",
      });
    }
  };

  const expandedRowRender = (record) => (
    <div className="expandid">
      <span>
        Nome:{" "}
        <p dangerouslySetInnerHTML={{ __html: record.user.username }} />
      </span>
      <span>
        Cargo:{" "}
        <p style={{ textTransform: "capitalize" }} className={`badge ${record.is_superuser ? "badge-success" : "badge-error"}`}>
          {record.is_superuser ? "Administrador" : "Vendedor"}
        </p>
      </span>
      <span>
        Email:{" "}
        <p
          className="truncate-text"
          dangerouslySetInnerHTML={{ __html: record.user.email }}
        />
      </span>
      <span>
        Entrada no sistema:{" "}
        <p dangerouslySetInnerHTML={{ __html: formatDate(record.created_at) }} />
      </span>
      <span style={{ marginTop: "10px" }}>
        Informação:{" "}
        <Link to={`/admin/acessos/${record.user.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      </span>
      <span style={{ marginTop: "10px" }}>
        Deletar:{" "}
        <DeleteOutlined onClick={() => confirmDelete(record)} style={{ cursor: "pointer", height: "100%" }} />
      </span>
    </div>
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setOpenCreateModal(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsSecondModalVisible(true)
  };

  const fetchOptions = async (search) => {
    setLoading(true);
    try {
      const json = await apiAccessList({ search });
      setOptions(json.results.map(item => ({ label: item.email, value: item.id })));
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchText !== '') {
      fetchOptions(searchText);
    } else {
      setOptions([]);
    }
  }, [searchText]);

  const handleSecondModalCancel = () => {
    setIsSecondModalVisible(false);
  };

  const handleCreateAccount = async () => {
    try {
      await apiCreateAccessAccount(options[0].value, dataInfo.id).then(json => {
        notification.success({
          message: "Acesso criado com sucesso",
          description: "Acesso criado com sucesso",
          placement: "topRight",
        });
        setIsModalVisible(false);
        setIsSecondModalVisible(false)
        getAccesses();
      })
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro ao criar o acesso.",
        placement: "topRight",
      });
    }
  };

  const [openCreateModal, setOpenCreateModal] = useState(false);

  return (
    <div className="AccessAccount">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{ backgroundColor: 'white' }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <p class="subtitle-page">Usuários com acesso</p>
              <Divider />
            </Breadcrumb>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
              }}
            >
              <div className="box-divider">
                <Button onClick={showModal} type="primary">
                  Adicionar novo acesso
                </Button>
              </div>
              <Divider />
              {loading ? (
                <Skeleton active />
              ) : (
                <Table
                  columns={columns}
                  locale={locale}
                  dataSource={data.map((item) => ({ ...item, key: item.id }))}
                  expandable={{
                    expandedRowRender: isMobile ? expandedRowRender : undefined,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => handleRowClick(record),
                    responsive: ["xl"],
                  }}
                  pagination={{
                    current: pagination.current,
                    total: pagination.total,
                    showSizeChanger: false,
                  }}
                  bordered
                />
              )}
            </Content>
            <Divider />
          </Layout>
          <Modal
            title="Escolha uma opção"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type="primary"
                onClick={handleOk}
                style={{ marginRight: '8px', width: '100%' }}
              >
                Novo usuário
              </Button>
              <Button
                type="default"
                style={{ width: '100%' }}
                onClick={handleCancel}
              >
                Usuário existente
              </Button>
            </div>
          </Modal>
          <Modal
            title="Confirmação"
            visible={isSecondModalVisible}
            onCancel={handleSecondModalCancel}
            footer={null}
          >
            <div style={{ marginBottom: '16px' }}>
              <Select
                showSearch
                placeholder="Selecione um usuário"
                notFoundContent={loading ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={value => setSearchText(value)}
                style={{ width: '100%' }}
              >
                {options.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <Button
              type="primary"
              onClick={handleCreateAccount}
              style={{ marginRight: '8px' }}
            >
              Confirmar
            </Button>
            <Button
              type="default"
              onClick={handleSecondModalCancel}
            >
              Cancelar
            </Button>
          </Modal>
          <AccessCreateModal visible={openCreateModal} onClose={() => setOpenCreateModal(false)} />
        </>
      )}
    </div>
  );
};

export default AccessAccount;

import { Breadcrumb, Button, Divider, Input, Layout, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { apiSimulationTax } from '../../../api/simulation';
import SellerDropdown from '../../common/SellerDropdown/SellerDropdown';
import PaymentTable from './PaymentTable/PaymentTable';
import './SimulationTax.scss';
const SimulationTax = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [inputValue, setInputValue] = useState('100');
  const [inputValueMask, setInputValueMask] = useState('100');
  const [dataTable, setDataTable] = useState([])

  const handleChange = (e) => {
    setInputValue(formatCurrency(e.target.value));
  };

  const formatCurrency = (value) => {
    setInputValueMask(value)
    const cleanValue = value.replace(/[^\d]/g, '');
    const formattedValue = cleanValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return `${formattedValue}`;
  };

  const handleSimulate = (value) => {
    apiSimulationTax(value || inputValueMask)
      .then(json => {
        setDataTable(json)
      })
      .catch(() => { });
  }

  useEffect(() => {
    handleSimulate(100)
  }, [])

  return (
    <div className='SimulationTax'>
      <Layout
        style={{
          padding: '1.25rem',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <h2 className='title-page'>Simulador</h2>
            <SellerDropdown />
          </div>
          <Divider />
          <Breadcrumb.Item>Simulador</Breadcrumb.Item>
          <Breadcrumb.Item>Simulador</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className='subtitle-page'>1. Dados da simulação</h3>
          <Divider />
          <div className="container-serch">
            <div className='box-input'>
              <p>Valor da venda</p>
              <Input
                placeholder='R$ 0,00'
                value={inputValue}
                onChange={handleChange}
              />
            </div>
            <Button type="primary" onClick={() => handleSimulate()}>Simular</Button>
          </div>
        </Content>

        <Content
          style={{
            padding: 24,
            margin: '20px 0',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className='subtitle-page'>Encargos ao lojista</h3>
          <Divider />
          <PaymentTable mockObject={dataTable.seller_liable} />
        </Content>

        <Content
          style={{
            padding: 24,
            margin: '20px 0',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className='subtitle-page'>Encargos ao comprador</h3>
          <Divider />
          <PaymentTable mockObject={dataTable.buyer_liable} />
        </Content>
      </Layout>
    </div>
  )
}

export default SimulationTax

import { DeleteOutlined, InboxOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  Layout,
  message,
  Modal,
  notification,
  Result,
  Select,
  Skeleton,
  Space,
  Table,
  theme
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Option } from "antd/es/mentions";
import Dragger from "antd/es/upload/Dragger";
import dayjs from "dayjs";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiCreateEventLogo, apiCreateEventTicket, apiDeleteEvent, apiDeleteEventTicket, apiEditEvent, apiEditEventTicket, apiGetEvent, apiGetEventTickets, apiListEventsPay } from "../../../../api/events";
import { EVENT_CATEGORY_CHOICES, EVENT_NOMENCLATURE_CHOICES, EVENT_SUBJECT_CHOICES } from "../../../../utils/eventsSelect";
import { formatPrice } from "../../../../utils/format";
import AddQuestions from "../AddQuestions/AddQuestions";
import "./EventDetails.scss";

const EventDetails = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameClature, setNameClature] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [fileList, setFileList] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState({
    address_name: '',
    address_street: '',
    address_number: '',
    address_complement: '',
    address_neighborhood: '',
    address_city: '',
    address_state: '',
    address_postal_code: '',
  });
  const [loading, setLoading] = useState(false);
  const handleEditorChange = (newContent) => {
    setDescription(newContent);
  };

  const config = {
    readonly: !isEditing,
    placeholder: 'Digite aqui...',
    buttons: [
      'bold', 'italic', '|', 'ul', 'ol', '|', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',
    ],
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
  };

  // ticket
  const [nameTicket, setNameTicket] = useState();
  const [amount, setAmount] = useState({ price: 0 });
  const [descriptionTicket, setDescriptionTicket] = useState();
  const [starterQuestionList, setStarterQuestionList] = useState([]);
  const [questions, setQuestions] = useState([]);

  const addQuestion = () => {
    const questionList = [...questions, ""];
    setQuestions(questionList);
  };

  // PAYMENT LIST
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState(null);
  // Paginação
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  // Função de busca
  const handleSearch = async (value) => {
    setSearchText(value);
    setLoadingPayment(true)
    try {
      if (value === '') {
        await fetchPayments(pagination.current);
      } else {
        const json = await apiListEventsPay(id, {
          search: value,
          status: statusFilter,
          page: 1,
          page_size: pagination.pageSize,
        });
        setPayments(json.results || []);
        setPagination({
          ...pagination,
          total: json.count || 0,
          current: 1,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoadingPayment(false)
    }
  };

  // Função de busca com paginação
  const fetchPayments = async (page) => {
    setLoadingPayment(true)
    try {
      const json = await apiListEventsPay(id, {
        search: searchText,
        status: statusFilter,
        page: page,
        page_size: pagination.pageSize,
      });
      setPayments(json.results || []);
      setPagination({
        ...pagination,
        total: json.count || 0,
        current: page,
      });
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoadingPayment(false)
    }
  };

  useEffect(() => {
    if (id) {
      fetchPayments(pagination.current);
    }
  }, [searchText, statusFilter, pagination.pageSize]);

  const handleTableChange = (pagination) => {
    fetchPayments(pagination.current);
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value === undefined ? '' : value);
    setPagination(prev => ({
      ...prev,
      current: 1,
    }));
  };

  const statusTranslations = {
    FREE: "Gratuito",
    EXPIRED: "Expirado",
    PENDING: "Pendente",
    PAID: "Pago",
    CANCELED: "Cancelado"
  };


  const columnsPayment = [
    {
      title: 'Nome',
      dataIndex: ['customer', 'name'],
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => statusTranslations[status],
      responsive: ["sm"]
    },
    {
      title: 'Email',
      dataIndex: ['customer', 'email'],
      key: 'email',
      responsive: ["lg"],
    },
    {
      title: 'Preço',
      dataIndex: 'total',
      key: 'total',
      render: total => `R$ ${(total / 100).toFixed(2)}`,
    },
    {
      title: 'Data da compra',
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => new Date(created_at).toLocaleString(),
      responsive: ["xl"],
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => navigate(`/eventos/detalhe/${id}/${record.id}`)}>
          Visualizar
        </Button>
      ),
    },
  ];

  const parseJSON = (text) => {
    try {
      return JSON.parse(text);
    } catch (error) {
      return text;
    }
  };

  useEffect(() => {
    setLoading(true);

    if (id) {
      apiGetEvent(id)
        .then(async (response) => {
          const { address, ...eventDetails } = response;
          form.setFieldsValue({
            name: eventDetails.name || "",
            description: parseJSON(eventDetails.description || ""),
            category: eventDetails.category || "",
            subject: eventDetails.subject || "",
            nameClature: eventDetails.nomenclature || "",
            startDate: eventDetails.starts_at ? dayjs(eventDetails.starts_at) : null,
            endDate: eventDetails.ends_at ? dayjs(eventDetails.ends_at) : null,
            address_name: address?.name || '',
            address_street: address?.street || '',
            address_number: address?.number || '',
            address_complement: address?.complement || '',
            address_neighborhood: address?.neighborhood || '',
            address_city: address?.city || '',
            address_state: address?.state || '',
            address_postal_code: address?.postal_code || ''
          });

          // Atualiza o estado do componente
          setName(eventDetails.name || "");
          setDescription(parseJSON(eventDetails.description) || "");
          setNameClature(eventDetails.nomenclature || "");
          setCategory(eventDetails.category || "");
          setSubject(eventDetails.subject || "");
          setStartDate(eventDetails.starts_at ? dayjs(eventDetails.starts_at) : null);
          setEndDate(eventDetails.ends_at ? dayjs(eventDetails.ends_at) : null);
          setAddress({
            address_name: address?.name || '',
            address_street: address?.street || '',
            address_number: address?.number || '',
            address_complement: address?.complement || '',
            address_neighborhood: address?.neighborhood || '',
            address_city: address?.city || '',
            address_state: address?.state || '',
            address_postal_code: address?.postal_code || '',
          });
          setImageUrl(eventDetails.image_url || '');
          setLoading(false);

          const ticketsApi = await apiGetEventTickets(id);
          if (ticketsApi && ticketsApi.results) {
            const transformTickets = (tickets) => {
              return tickets.results.map(ticket => ({
                id: ticket.id,
                name: ticket.name,
                description: ticket.description,
                amount: ticket.amount,
                questions: ticket.questions.map(q => q.prompt),
                starterQuestionList: ticket.questions.map(q => ({
                  prompt: q.prompt,
                  type: q.type
                }))
              }));
            };
            const transformedTickets = transformTickets(ticketsApi);
            setTickets(transformedTickets);
          }
        })
        .catch((error) => {
          console.log(error)
          setLoading(false);
          setActiveError(true)
          notification.error({
            message: "Erro",
            description: "Erro ao os dados do evento.",
            placement: "topRight",
          });
          setErrorStatus("400");
        });
    } else {
      setLoading(false);
    }
  }, [id]);
  const formatDateForSubmission = (date) => {
    if (!date) return null;
    return date.format("YYYY-MM-DDTHH:mm:ss");
  };

  const handleNameClature = (value) => {
    setNameClature(value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleSubjectChange = (value) => {
    setSubject(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date ? dayjs(date) : null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? dayjs(date) : null);
  };

  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        if (!description || description.trim() === '') {
          message.error("Por favor, insira a descrição.");
          return;
        }

        if (tickets.length === 0) {
          message.error("Por favor, adicione pelo menos um ticket.");
          return;
        }

        handleEdit();
      })
      .catch(() => {
        message.error("Preencha todos os campos obrigatórios.");
      });
  };

  const buildPayload = (imageUrl) => ({
    name,
    description: JSON.stringify(description),
    subject,
    category,
    nomenclature: nameClature,
    starts_at: formatDateForSubmission(startDate),
    ends_at: formatDateForSubmission(endDate),
    image_url: imageUrl,
    address: {
      name: address.address_name,
      street: address.address_street,
      number: address.address_number,
      complement: address.address_complement,
      neighborhood: address.address_neighborhood,
      city: address.address_city,
      state: address.address_state,
      postal_code: address.address_postal_code
    }
  });

  const handleEdit = async () => {
    let currentImageUrl = imageUrl;

    setLoading(true);

    try {
      if (fileList && fileList.length > 0) {
        try {
          const logoResponse = await apiCreateEventLogo(fileList[0].originFileObj);
          currentImageUrl = logoResponse.url;
          setImageUrl(currentImageUrl);
        } catch (uploadError) {
          message.error("Erro ao enviar a imagem. Verifique o tamanho e o formato da imagem.");
          return;
        }
      }

      const payload = buildPayload(currentImageUrl);
      await apiEditEvent(id, payload);

      for (const ticket of tickets) {
        const payloadTicket = {
          name: ticket.name,
          description: ticket.description,
          amount: Math.floor(ticket.amount),
          questions: ticket.starterQuestionList,
        };
        if (ticket.new) {
          await apiCreateEventTicket(id, payloadTicket);
        } else {
          await apiEditEventTicket(id, ticket.id, payloadTicket);
        }
      }

      message.success("Evento editado com sucesso!");
      navigate('/eventos');
      setIsEditing(false);

    } catch (error) {
      message.error("Erro ao editar o evento. Verifique os detalhes e tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleEdit = () => {
    setIsEditing(prev => !prev);
  };

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const handleDelete = async () => {
    try {
      for (const ticket of tickets) {
        if (!ticket.new) {
          const deleteResponse = await apiDeleteEventTicket(id, ticket.id);
          if (!deleteResponse.success) {
            message.error(`Falha ao deletar o ticket com ID ${ticket.id}.`);
            return;
          }
        }
      }

      const eventResponse = await apiDeleteEvent(id);
      if (eventResponse.success) {
        message.success("Evento e ingressos deletados com sucesso!");
        navigate('/eventos');
      } else {
        message.error("Falha ao deletar o evento. Todos os ingressos foram deletados.");
      }

    } catch (error) {
      message.error("Erro ao deletar o evento. Verifique os detalhes e tente novamente.");
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja deletar?',
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Deletar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => handleDelete(),
    });
  };

  const handleUploadChange = (info) => {
    const newFileList = info.fileList.slice(-1);
    setFileList(newFileList);
    setImageUrl();
    if (info.file.status === "done") {
      message.success(`${info.file.name} arquivo carregado com sucesso`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} falha no carregamento`);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    onChange: handleUploadChange,
    maxCount: 1,
    accept: ".jpeg,.jpg,.png,.gif",
    beforeUpload: () => false,
    fileList
  };

  const validateImage = (_, value) => {
    if (value && value.length > 0) {
      return Promise.resolve();
    }
    if (imageUrl) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Por favor, selecione uma imagem."));
  };

  const [tickets, setTickets] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditingModal, setIsEditingModal] = useState(false);
  const [editingTicketId, setEditingTicketId] = useState(null);
  const [internalAmount, setInternalAmount] = useState("");
  const [errors, setErrors] = useState({
    name: '',
    description: '',
    amount: ''
  });

  const validateFields = () => {
    const newErrors = {
      name: '',
      description: '',
      amount: ''
    };

    if (!nameTicket) newErrors.name = 'Por favor, insira o nome do ticket!';
    if (!descriptionTicket) newErrors.description = 'Por favor, insira a descrição do ticket!';
    if (!isFree && internalAmount <= 0) newErrors.amount = 'Por favor, insira um preço válido!';

    setErrors(newErrors);

    return !newErrors.name && !newErrors.description && !newErrors.amount;
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    const numericValue = value.replace(/[^0-9]/g, '');
    setInternalAmount(parseInt(numericValue, 10) || 0);
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsFree(checked);
    if (checked) {
      setAmount(0);
      setInternalAmount(0);
    }
  };

  const openModal = () => {
    setIsModalVisible(true);
    setIsEditingModal(false);
    setNameTicket('');
    setDescriptionTicket('');
    setAmount('');
    setInternalAmount(0);
    setStarterQuestionList([]);
  };

  const openModalToEdit = (ticket) => {
    setIsEditingModal(true);
    setEditingTicketId(ticket.id);
    setNameTicket(ticket.name);
    setDescriptionTicket(ticket.description);
    setAmount(ticket.amount);
    setInternalAmount(ticket.amount)
    setQuestions(ticket.questions || []);
    setStarterQuestionList(ticket.starterQuestionList || []);
    setIsModalVisible(true);
    setErrors({})
    setIsFree(ticket.amount === 0);
  };

  const handleSaveTicket = () => {
    if (validateFields()) {
      if (isEditingModal) {
        const updatedTickets = tickets.map((ticket) => {
          if (ticket.id === editingTicketId) {
            return {
              ...ticket,
              name: nameTicket,
              description: descriptionTicket,
              amount: internalAmount,
              questions: questions,
              starterQuestionList: starterQuestionList,
            };
          }
          return ticket;
        });
        setTickets(updatedTickets);
      } else {
        setTickets([
          ...tickets,
          {
            id: new Date().getTime(),
            name: nameTicket,
            description: descriptionTicket,
            amount: internalAmount,
            new: true,
            questions: questions,
            starterQuestionList: starterQuestionList,
          },
        ]);
      }

      setIsModalVisible(false);
      resetForm();
    }
  };

  const handleDeleteTicket = async () => {
    try {
      const updatedTickets = tickets.filter(ticket => ticket.id !== editingTicketId);
      const ticketToDelete = tickets.find(ticket => ticket.id === editingTicketId);
      if (ticketToDelete && !ticketToDelete.new) {
        const deleteResponse = await apiDeleteEventTicket(id, ticketToDelete.id);
        if (!deleteResponse.success) {
          message.error("Falha ao deletar o ticket. Tente novamente.");
          return;
        }
      }
      setTickets(updatedTickets);
      setIsModalVisible(false);
      resetForm();
    } catch (error) {
      message.error("Erro ao deletar o ticket. Verifique os detalhes e tente novamente.");
    }
  };

  const resetForm = () => {
    setNameTicket("");
    setDescriptionTicket("");
    setAmount();
    setQuestions([]);
    setStarterQuestionList([]);
    setInternalAmount(0);
    setIsFree(false);
  };

  const columns = [
    {
      title: 'Nome do Ticket',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      responsive: ["sm"]
    },
    {
      title: 'Preço',
      dataIndex: 'amount',
      key: 'price',
      render: (amount) => `${formatPrice(amount / 100)}`,
      responsive: ["sm"],
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => isEditing ? openModalToEdit(record) : message.error('Habilite a edição para visualizar o ticket.')}>
          Visualizar
        </Button>
      ),
    },
  ];

  return (
    <div className="EventDetails">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                {loading && <Skeleton active paragraph={{ rows: 1 }} />}
                {!loading && <>
                  <h2 className="title-page">{name}</h2>
                  <Button onClick={toggleEdit} type="primary">
                    {isEditing ? 'Cancelar Edição' : 'Editar'}
                  </Button>
                </>}
              </div>
              <Divider />
              {loading && <Skeleton active paragraph={{ rows: 0 }} />}
              {!loading && <>
                <Breadcrumb.Item className='goBack' onClick={() => navigate(-1)}>Eventos</Breadcrumb.Item>
                <Breadcrumb.Item>{name}</Breadcrumb.Item></>}
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: "30px 0 0 0",
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="container-subtitle">
                {loading && <Skeleton active paragraph={{ rows: 1 }} />}
                {!loading && <h3 className="subtitle-page">1. Infos do Evento</h3>}
              </div>
              <Divider />
              {loading && <Skeleton active paragraph={{ rows: 3 }} />}
              {!loading && <div className="container-inputs">
                <div className="container-left">
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="Nome do Evento"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o nome do evento.",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Categoria"
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione a categoria.",
                        },
                      ]}
                    >
                      <Select
                        value={category}
                        onChange={handleCategoryChange}
                        placeholder="Selecione a categoria"
                        disabled={!isEditing}
                      >
                        {EVENT_CATEGORY_CHOICES.map((item) => (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Assunto"
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione o assunto.",
                        },
                      ]}
                    >
                      <Select
                        value={subject}
                        onChange={handleSubjectChange}
                        placeholder="Selecione o assunto"
                        disabled={!isEditing}
                      >
                        {EVENT_SUBJECT_CHOICES.map((item) => (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </div>
                <div className="container-right">
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="Nomenclatura"
                      name="nameClature"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione a nomenclatura.",
                        },
                      ]}
                    >
                      <Select
                        value={nameClature}
                        onChange={handleNameClature}
                        placeholder="Selecione a nomenclatura"
                        disabled={!isEditing}
                      >
                        {EVENT_NOMENCLATURE_CHOICES.map((item) => (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Data de Início"
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione a data de início.",
                        },
                      ]}
                    >
                      <DatePicker
                        showTime
                        onChange={handleStartDateChange}
                        value={startDate}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Data de Fim"
                      name="endDate"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione a data de fim.",
                        },
                      ]}
                    >
                      <DatePicker
                        showTime
                        onChange={handleEndDateChange}
                        value={endDate}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>}

              {!loading &&
                <div className="container-inputs" style={{ width: "100%" }}>
                  <Form form={form} style={{ width: "100%" }}>
                    <Form.Item
                      label="Imagem de divulgação"
                      name="eventImage"
                      className="required"
                      valuePropName="fileList"
                      rules={[{ validator: validateImage }]}
                      getValueFromEvent={({ fileList }) => fileList}
                    >
                      <Dragger {...props}>
                        {imageUrl || (fileList && fileList.length > 0 && fileList[0].originFileObj) ? (
                          <img
                            src={imageUrl || URL.createObjectURL(fileList[0].originFileObj)}
                            alt="preview"
                            style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                          />
                        ) : (
                          <>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ fontSize: "32px", color: "#1890ff" }} />
                            </p>
                            <p className="ant-upload-text">Clique ou arraste a imagem aqui</p>
                            <p className="ant-upload-hint">
                              A dimensão recomendada é de <strong>1600 x 838</strong> (mesma proporção do formato utilizado nas páginas de evento no Facebook). Formato <strong>JPEG, GIF ou PNG</strong> de no máximo <strong>2MB</strong>. Imagens com dimensões diferentes serão redimensionadas.
                            </p>
                          </>
                        )}
                      </Dragger>
                    </Form.Item>
                  </Form>
                </div>}
              {!loading &&
                <div className="container-width required" style={{ marginTop: 30 }}>
                  <label>Descrição</label>
                  <JoditEditor
                    disabled
                    value={description}
                    config={config}
                    onBlur={handleEditorChange}
                    onChange={(newContent) => { }}
                  />
                </div>}
            </Content>

            {!loading && <Content
              style={{
                padding: 24,
                margin: "30px 0 0 0",
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="container-subtitle">
                <h3 className="subtitle-page">2. Plano de Pagamento</h3>
              </div>
              <Divider />
              <div className="container-inputs">
                <div className="container-left">
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="Nome Comercial"
                      name="address_name"
                      rules={[{ required: true, message: 'Por favor, insira o nome comercial.' }]}
                    >
                      <Input
                        name="address_name"
                        onChange={handleChange}
                        value={address.address_name}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Rua"
                      name="address_street"
                      rules={[{ required: true, message: 'Por favor, insira a rua.' }]}
                    >
                      <Input
                        name="address_street"
                        onChange={handleChange}
                        value={address.address_street}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Número"
                      name="address_number"
                      rules={[{ required: true, message: 'Por favor, insira o número.' }]}
                    >
                      <Input
                        name="address_number"
                        onChange={handleChange}
                        value={address.address_number}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Complemento"
                      name="address_complement"
                      rules={[{ required: true, message: 'Por favor, insira o complemento.' }]}
                    >
                      <Input
                        name="address_complement"
                        onChange={handleChange}
                        value={address.address_complement}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                  </Form>
                </div>
                <div className="container-right">
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="Bairro"
                      name="address_neighborhood"
                      rules={[{ required: true, message: 'Por favor, insira o bairro.' }]}
                    >
                      <Input
                        name="address_neighborhood"
                        onChange={handleChange}
                        value={address.address_neighborhood}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Cidade"
                      name="address_city"
                      rules={[{ required: true, message: 'Por favor, insira a cidade.' }]}
                    >
                      <Input
                        name="address_city"
                        onChange={handleChange}
                        value={address.address_city}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Estado"
                      name="address_state"
                      rules={[{ required: true, message: 'Por favor, insira o estado.' }]}
                    >
                      <Input
                        name="address_state"
                        onChange={handleChange}
                        value={address.address_state}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                    <Form.Item
                      label="CEP"
                      name="address_postal_code"
                      rules={[{ required: true, message: 'Por favor, insira o CEP.' }]}
                    >
                      <Input
                        name="address_postal_code"
                        onChange={handleChange}
                        value={address.address_postal_code}
                        disabled={!isEditing}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Content>
            }
            {!loading &&
              <Content
                style={{
                  padding: 24,
                  margin: "30px 0 0 0",
                  minHeight: tickets.length > 0 ? 280 : 0,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <div className="container-subtitle" style={{ marginBottom: 30 }}>
                  <h3 className="subtitle-page">2. Tickets do evento</h3>
                  <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => {
                    isEditing ? openModal() : message.error('Habilite a criação de tickets.')
                  }}>Adicionar Ticket</Button>
                </div>
                {tickets.length > 0 && <Table
                  locale={{ emptyText: <Empty description="Sem tickets cadastrados" /> }}
                  columns={columns}
                  dataSource={tickets.map((item) => ({ ...item, key: item.id }))}
                />}
                {tickets.length === 0 && <p style={{ textAlign: 'center' }}>Não há tickets cadastrados. <br /> <strong style={{ color: "red" }}>(Obrigatório)</strong></p>}
                <Modal
                  title={isEditingModal ? "Editar Ticket" : "Adicionar Ticket"}
                  open={isModalVisible}
                  onCancel={() => setIsModalVisible(false)}
                  footer={[
                    isEditingModal && (
                      <Button key="delete" type="primary" danger icon={<DeleteOutlined />} onClick={handleDeleteTicket}>
                        Excluir
                      </Button>
                    ),
                    <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                      Cancelar
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSaveTicket}>
                      {isEditingModal ? "Salvar" : "Adicionar"}
                    </Button>
                  ]}
                >
                  <Form layout="vertical">
                    <Form.Item
                      label="Nome"
                      validateStatus={errors.name ? 'error' : ''}
                      help={errors.name}
                      className="required"
                    >
                      <Input
                        value={nameTicket}
                        onChange={(e) => setNameTicket(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Descrição"
                      validateStatus={errors.description ? 'error' : ''}
                      help={errors.description}
                      className="required"
                    >
                      <Input.TextArea
                        value={descriptionTicket}
                        onChange={(e) => setDescriptionTicket(e.target.value)}
                      />
                    </Form.Item>
                    <div>
                      <Form.Item
                        validateStatus={errors.amount ? 'error' : ''}
                        help={errors.amount}
                        style={{ margin: 0 }}
                        className="required"
                        label="Preço">

                        <Input
                          value={formatPrice(internalAmount / 100)}
                          onChange={handleAmountChange}
                          disabled={isFree}
                        />
                      </Form.Item>
                      <Form.Item className="checkbox-item">
                        <Checkbox
                          checked={isFree}
                          onChange={handleCheckboxChange}
                        >
                          Ingresso grátis
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="container-question-button" style={{ marginBottom: 30 }}>
                      <h3>Perguntas Iniciais</h3>
                      <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { addQuestion() }}
                      >
                        Nova Pergunta
                      </Button>
                    </div>
                    <AddQuestions
                      starterQuestionList={starterQuestionList}
                      setStarterQuestionList={setStarterQuestionList}
                      setQuestions={setQuestions}
                      questions={questions}
                    />
                  </Form>
                </Modal>

              </Content>
            }

            {!loading &&
              <Content
                style={{
                  padding: 24,
                  margin: "30px 0 0 0",
                  minHeight: tickets.length > 0 ? 280 : 0,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <div className="container-subtitle" style={{ marginBottom: 30 }}>
                  <h3 className="subtitle-page">3. Compras</h3>
                </div>
                <Space style={{ marginBottom: 16 }}>
                  <Input
                    placeholder="Pesquisar por Nome"
                    onChange={e => handleSearch(e.target.value)}
                  />
                  <Select
                    placeholder="Filtrar por status"
                    onChange={handleStatusChange}
                    allowClear
                    value={statusFilter}
                    style={{ width: 200 }}
                  >
                    <Option value="FREE">Gratuito</Option>
                    <Option value="EXPIRED">Expirado</Option>
                    <Option value="PENDING">Pendente</Option>
                    <Option value="PAID">Pago</Option>
                    <Option value="CANCELED">Cancelado</Option>
                    <Option value="">Todos</Option>
                  </Select>
                </Space>

                {error && <p style={{ color: 'red' }}>{error}</p>}
                {!loadingPayment && <Table
                  columns={columnsPayment}
                  dataSource={payments}
                  loading={loading}
                  rowKey="id"
                  locale={{ emptyText: <Empty description="Sem dados" /> }}
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: false,
                    onChange: (page, pageSize) => {
                      setPagination(prev => ({
                        ...prev,
                        current: page,
                        pageSize: pageSize,
                      }));
                      fetchPayments(page);
                    },
                  }}
                  onChange={handleTableChange}
                />}
                {loadingPayment && <Skeleton active paragraph={{ rows: 10 }} />}
              </Content>}
            <div className="container-buttons">
              {!loading && <Button
                onClick={confirmDelete}
                type="primary"
                danger
              >
                Apagar evento
              </Button>}
              {isEditing && (
                <Button
                  onClick={onFinish}
                  type="primary"
                >
                  Salvar Alterações
                </Button>
              )}
            </div>
          </Layout>
        </>)}
    </div>
  );
};

export default EventDetails;

import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Input,
  Layout,
  Result,
  Skeleton,
  Table,
  notification,
  theme,
} from "antd";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiAccessList } from "../../../../api/access";
import { useIsMobileHook } from "../../../../utils/WindowHooks";
import { formatDate } from "../../../../utils/format";
import AccessCreateModal from "../AccessCreate/AccessCreate";
import "./Access.scss";

const { Content } = Layout;

const Access = () => {
  const isMobile = useIsMobileHook(1600);
  const navigate = useNavigate();
  const [dataSales, setDataSale] = useState([]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSales);

  const handleRowClick = (record) => {
    const id = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];
    const index = newExpandedRowKeys.indexOf(id);
    if (index > -1) {
      newExpandedRowKeys.splice(index, 1);
    } else {
      newExpandedRowKeys.push(id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const handleSearch = async (value) => {
    setSearchText(value);
    setLoading(true);
    try {
      if (value === '') {
        await listAccess(pagination.current);
      } else {
        const json = await apiAccessList({ page: 1, search: value });
        setFilteredData(json.results);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: json.count,
          current: 1,
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const [loading, setLoading] = useState(true);

  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const listAccess = async (page) => {
    setLoading(true);
    try {
      const json = await apiAccessList({ page });
      setDataSale(json);
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: page,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      console.log(error)
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar os acessos.",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar os acessos. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("404");
        notification.error("Erro ao buscar os acessos.");
      }
    }
  };

  const onChange = (pagination) => {
    listAccess(pagination.current);
  };

  useEffect(() => {
    if (dataSales?.length <= 1) {
      listAccess(pagination.current);
    }
  }, []);

  const columns = [
    {
      title: "Nome",
      dataIndex: "username",
      key: "name",
      render: (confirmed) => (
        <p
          dangerouslySetInnerHTML={{ __html: confirmed }}
        />
      ),
    },
    {
      title: "Email para notificação",
      dataIndex: "email",
      key: "email",
      responsive: ["sm"],
    },
    {
      title: "Entrada no sistema",
      dataIndex: "date_joined",
      key: "date_joined",
      render: (date_joined) => (
        <p dangerouslySetInnerHTML={{ __html: formatDate(date_joined) }} />
      ),
      responsive: ["lg"],
    },
    {
      title: "Cargo",
      dataIndex: "is_superuser",
      key: "is_superuser",
      render: (is_superuser) => (
        <p style={{ textTransform: "capitalize" }} className={`badge ${is_superuser ? "badge-success" : "badge-error"}`}>
          {is_superuser ? "Administrador" : "Vendedor"}
        </p>
      ),
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link to={`/admin/acessos/${confirme.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />{" "}
        </Link>
      ),
      key: "",
      responsive: ["sm"],
    },
  ];

  const expandedRowRender = (record) => (
    <div className="expandid">
      <span>
        Nome:{" "}
        <p dangerouslySetInnerHTML={{ __html: record.username }} />
      </span>
      <span>
        Cargo:{" "}
        <p style={{ textTransform: "capitalize" }} className={`badge ${record.is_superuser ? "badge-success" : "badge-error"}`}>
          {record.is_superuser ? "Administrador" : "Vendedor"}
        </p>
      </span>
      <span>
        Email:{" "}
        <p
          dangerouslySetInnerHTML={{ __html: record.email }}
        />
      </span>
      <span>
        Entrada no sistema:{" "}
        <p dangerouslySetInnerHTML={{ __html: format(record.date_joined, "dd/MM/yyyy 'às' HH:mm:ss") }} />
      </span>
      <span style={{ marginTop: "10px" }}>
        Informação:{" "}
        <Link to={`/admin/acessos/${record.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      </span>
    </div>
  );

  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  const [openCreateModal, setOpenCreateModal] = useState(false);

  return (
    <div className="Access">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <h2 className="title-page">Usuários</h2>
              <Divider />
              <Breadcrumb.Item>Lista de Usuários</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="box-divider">
                <h2 className="subtitle-page">Usuários</h2>
                <Button
                  onClick={() => setOpenCreateModal(true)}
                  type="primary"
                >
                  Criar um novo usuário
                </Button>
              </div>
              <Divider />
              {/* TODO: Implementar busca */}
              <Input.Search
                placeholder='Pesquisar'
                allowClear
                enterButton
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 16, maxWidth: '200px' }}
              />
              {loading ? (
                <Skeleton active />
              ) : (
                <Table
                  columns={columns}
                  locale={locale}
                  dataSource={
                    filteredData &&
                    filteredData.map((item) => ({ ...item, key: item.id }))
                  }
                  pagination={{
                    current: pagination.current,
                    total: pagination.total,
                    showSizeChanger: false,
                  }}
                  expandable={{
                    expandedRowRender: isMobile ? expandedRowRender : undefined,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => handleRowClick(record),
                    responsive: ["xl"],
                  }}
                  bordered
                  onChange={onChange}
                />
              )}
            </Content>
            <Divider />
          </Layout>
          <AccessCreateModal visible={openCreateModal} onClose={() => setOpenCreateModal(false)} />
        </>
      )}
    </div>
  );
};

export default Access;

import React, { useState } from 'react';
import { Table, Input, Button, Modal } from 'antd';
import TaxAssistantModal from './TaxAssistantModal';

const SplitDetailsTable = ({ splitDetails, zoopPlans }) => {
  const parseJSON = (jsonString) => {
    try {
      const validJsonString = jsonString.replace(/'/g, '"');
      return JSON.parse(validJsonString);
    } catch (e) {
      console.error("Invalid JSON string:", jsonString);
      return {};
    }
  };

  const initializeData = (splitDetails, zoopPlans) => {
    const initialData = typeof splitDetails === 'string' ? parseJSON(splitDetails) : splitDetails;

    const paymentTypes = ['credit', 'debit'];
    const installments = {
      credit: Object.keys(zoopPlans.details.fees.credit || {}),
      debit: ['1'] 
    };
    const brands = [...zoopPlans.details.brands, 'Boleto', 'Pix'];
    const receivers = ['Administrador', 'Agente', 'Distribuidor N1', 'Distribuidor N2', 'Programador'];

    paymentTypes.forEach(paymentType => {
      if (!initialData.details) initialData.details = {};
      if (!initialData.details.fees) initialData.details.fees = {};
      if (!initialData.details.fees[paymentType]) initialData.details.fees[paymentType] = {};

      installments[paymentType].forEach(installment => {
        if (!initialData.details.fees[paymentType][installment]) initialData.details.fees[paymentType][installment] = {};

        brands.forEach(brand => {
          if (!initialData.details.fees[paymentType][installment][brand]) initialData.details.fees[paymentType][installment][brand] = {};

          receivers.forEach(receiver => {
            if (initialData.details.fees[paymentType][installment][brand][receiver] === undefined) {
              initialData.details.fees[paymentType][installment][brand][receiver] = 0;
            }
          });
        });
      });
    });

    return initialData;
  };

  const [data, setData] = useState(initializeData(splitDetails, zoopPlans));
  const [modalVisible, setModalVisible] = useState(false);
  const [editingValues, setEditingValues] = useState({});
  const handleInputChange = (value, paymentType, installments, brand, receiver) => {
    const newValue = parseFloat(value.replace(',', '.'));
    if (isNaN(newValue)) return;

    const newData = { ...data };
    if (!newData.details) newData.details = {};
    if (!newData.details.fees) newData.details.fees = {};
    if (!newData.details.fees[paymentType]) newData.details.fees[paymentType] = {};
    if (!newData.details.fees[paymentType][installments]) newData.details.fees[paymentType][installments] = {};
    if (!newData.details.fees[paymentType][installments][brand]) newData.details.fees[paymentType][installments][brand] = {};
    newData.details.fees[paymentType][installments][brand][receiver] = newValue;

    setEditingValues((prev) => ({
      ...prev,
      [`${paymentType}-${installments}-${brand}-${receiver}`]: newValue,
    }));

    setData(newData);
  };

  const handleInputFocus = (paymentType, installments, brand, receiver, value) => {
    setEditingValues((prev) => ({
      ...prev,
      [`${paymentType}-${installments}-${brand}-${receiver}`]: value || 0,
    }));
  };

  const getRowData = (paymentType, installments, brand, receiver) => {
    const key = `${paymentType}-${installments}-${brand}-${receiver}`;
    const value =
      editingValues[key] !== undefined
        ? editingValues[key]
        : data.details &&
          data.details.fees &&
          data.details.fees[paymentType] &&
          data.details.fees[paymentType][installments] &&
          data.details.fees[paymentType][installments][brand] &&
          data.details.fees[paymentType][installments][brand][receiver] !== undefined
          ? data.details.fees[paymentType][installments][brand][receiver]
          : 0;

    return (
      <Input
        value={value.toString()}
        onChange={(e) => handleInputFocus(paymentType, installments, brand, receiver, e.target.value)}
        onBlur={(e) => handleInputChange(e.target.value, paymentType, installments, brand, receiver)}
      />
    );
  };

  const getFinalValue = (paymentType, installments, brand) => {
    let zoopTax = 0;
    let receiversSum = 0;

    if (
      data.details &&
      data.details.fees &&
      data.details.fees[paymentType] &&
      data.details.fees[paymentType][installments] &&
      data.details.fees[paymentType][installments][brand]
    ) {
      for (let key of Object.keys(data.details.fees[paymentType][installments][brand])) {
        receiversSum += data.details.fees[paymentType][installments][brand][key];
      }
    }

    if (brand !== "Boleto" && brand !== "Pix") {
      const planData = zoopPlans.details.fees;
      if (
        planData[paymentType] &&
        planData[paymentType][installments] &&
        planData[paymentType][installments][brand] &&
        planData[paymentType][installments][brand][0]
      ) {
        zoopTax = planData[paymentType][installments][brand][0].percent_amount / 100;
      }
    }

    return (receiversSum + zoopTax).toFixed(2);
  };

  const handleOk = (paymentType, installments, receiver, tax, sameTax = false) => {
    const newData = { ...data };
    if (!newData.details) newData.details = {};
    if (!newData.details.fees) newData.details.fees = {};
    if (!newData.details.fees[paymentType]) newData.details.fees[paymentType] = {};
    if (!newData.details.fees[paymentType][installments]) newData.details.fees[paymentType][installments] = {};

    const allBrands = [...zoopPlans.details.brands, 'Boleto', 'Pix'];

    if (sameTax) {
      let requiresReduction = false;
      let cannotMeetFinalTax = false;

      for (let brand of allBrands) {
        if (!newData.details.fees[paymentType][installments][brand]) {
          newData.details.fees[paymentType][installments][brand] = {};
        }
        const baseTax = zoopPlans.details.fees[paymentType] &&
          zoopPlans.details.fees[paymentType][installments] &&
          zoopPlans.details.fees[paymentType][installments][brand] &&
          zoopPlans.details.fees[paymentType][installments][brand][0]
          ? zoopPlans.details.fees[paymentType][installments][brand][0].percent_amount / 100
          : 0;
        const currentTax = newData.details.fees[paymentType][installments][brand][receiver] || 0;
        const additionalTax = tax - baseTax;

        if (additionalTax > currentTax) {
          requiresReduction = true;
        }

        if (additionalTax < 0) {
          cannotMeetFinalTax = true;
        }
      }

      if (cannotMeetFinalTax) {
        Modal.confirm({
          title: 'Confirmação de Limite',
          content: `Para atingir a taxa final de ${tax}%, em alguns casos zerar a taxa do recebedor (${receiver}) não será o suficiente. O que deseja fazer?`,
          okText: 'Somente alterar onde for possível',
          cancelText: 'Cancelar operação',
          onOk() {
            applyFinalTax(newData, paymentType, installments, receiver, tax, allBrands, true);
          },
        });
      } else if (requiresReduction) {
        Modal.confirm({
          title: 'Confirmação de Redução',
          content: `Para atingir a taxa final de ${tax}%, o ganho do recebedor (${receiver}) precisará ser reduzido em alguns casos. Prosseguir com a alteração?`,
          onOk() {
            applyFinalTax(newData, paymentType, installments, receiver, tax, allBrands);
          },
        });
      } else {
        applyFinalTax(newData, paymentType, installments, receiver, tax, allBrands);
      }
    } else {
      for (let brand of allBrands) {
        if (!newData.details.fees[paymentType][installments][brand]) {
          newData.details.fees[paymentType][installments][brand] = {};
        }
        newData.details.fees[paymentType][installments][brand][receiver] = tax;
      }
    }

    setData(newData);
    setEditingValues({});
  };

  const applyFinalTax = (newData, paymentType, installment, receiver, tax, allBrands, partial = false) => {
    if (!newData.details.fees[paymentType][installment]) {
      newData.details.fees[paymentType][installment] = {};
    }
    for (let brand of allBrands) {
      if (!newData.details.fees[paymentType][installment][brand]) {
        newData.details.fees[paymentType][installment][brand] = {};
      }
      const baseTax = zoopPlans.details.fees[paymentType] &&
        zoopPlans.details.fees[paymentType][installment] &&
        zoopPlans.details.fees[paymentType][installment][brand] &&
        zoopPlans.details.fees[paymentType][installment][brand][0]
        ? zoopPlans.details.fees[paymentType][installment][brand][0].percent_amount / 100
        : 0;
      const currentTax = newData.details.fees[paymentType][installment][brand][receiver] || 0;
      const additionalTax = tax - baseTax;

      if (partial && additionalTax < 0) {
        newData.details.fees[paymentType][installment][brand][receiver] = 0;
      } else if (additionalTax < 0) {
        newData.details.fees[paymentType][installment][brand][receiver] = additionalTax;
      } else {
        newData.details.fees[paymentType][installment][brand][receiver] = additionalTax;
      }
    }
    setData(newData);
  };

  const installmentsOptions = [
    ...new Set([
      ...Object.keys(zoopPlans.details.fees.credit || {}),
      ...Object.keys(zoopPlans.details.fees.debit || {}),
    ]),
  ];

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left'
    },
    {
      title: '',
      dataIndex: 'installments',
      key: 'installments',
      fixed: 'left'
    },
    {
      title: 'Boleto',
      dataIndex: 'Boleto',
      key: 'Boleto'
    },
    {
      title: 'Pix',
      dataIndex: 'Pix',
      key: 'Pix'
    },
    ...zoopPlans.details.brands.map(brand => ({
      title: brand,
      dataIndex: brand,
      key: brand
    })),
  ];

  const brandsWithManual = [...zoopPlans.details.brands, 'Boleto', 'Pix'];

  const installments = {
    credit: Object.keys(zoopPlans.details.fees.credit || {}),
    debit: ['1'] 
  };

  const dataSource = zoopPlans.details.types.map((paymentType, typeIndex) => {
    const paymentTypeName = paymentType === 'credit' ? 'Crédito' : 'Débito';

    return {
      key: typeIndex,
      type: paymentTypeName,
      installments: '',
      children: installments[paymentType].map((installment, instIndex) => {
        return {
          key: `${typeIndex}-${instIndex}`,
          type: '',
          installments: `${installment}x`,
          ...brandsWithManual.reduce((acc, brand) => {
            acc[brand] = getFinalValue(paymentType, installment, brand);
            return acc;
          }, {}),
          children: [
            {
              key: `${typeIndex}-${instIndex}-base`,
              type: 'Taxa Base',
              installments: '',
              ...brandsWithManual.reduce((acc, brand) => {
                if (
                  zoopPlans.details.fees[paymentType] &&
                  zoopPlans.details.fees[paymentType][installment] &&
                  zoopPlans.details.fees[paymentType][installment][brand] &&
                  zoopPlans.details.fees[paymentType][installment][brand][0]
                ) {
                  acc[brand] = (zoopPlans.details.fees[paymentType][installment][brand][0].percent_amount / 100).toFixed(2);
                } else {
                  acc[brand] = '0.00';
                }
                return acc;
              }, {})
            },
            ...['Administrador', 'Agente', 'Distribuidor N1', 'Distribuidor N2', 'Programador'].map((receiver, recIndex) => ({
              key: `${typeIndex}-${instIndex}-${recIndex}`,
              type: receiver,
              installments: '',
              ...brandsWithManual.reduce((acc, brand) => {
                acc[brand] = getRowData(paymentType, installment, brand, receiver);
                return acc;
              }, {})
            }))
          ]
        };
      })
    };
  });
  return (
    <>
      <Button style={{marginBottom: "30px"}} type="primary" onClick={() => setModalVisible(true)}>Assistente de taxas</Button>
      <TaxAssistantModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleOk}
        installmentsOptions={installmentsOptions}
      />
      <Table
        className='splitTable'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 'max-content', y: 800 }}
        sticky
      />
    </>
  );
};

export default SplitDetailsTable;

import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Select,
  message,
  notification,
  theme,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Option } from "antd/es/mentions";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCheckoutCreate, apiPaymentCreate } from "../../../api/payment";
import InputMoney from "../../common/InputMoney/InputMoney";
import "./PaymentCreate.scss";
const PaymentCreate = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [boleto, setBoleto] = useState();
  const [credit, setCredit] = useState();
  const [pix, setPix] = useState();
  const [boletoMonthDay, setBoletoMothDay] = useState();
  const [maxInstallments, setMaxInstallments] = useState();
  const [maxBoletonstallments, setMaxBoletonstallments] = useState();
  const [maxInstallmentsTax, setMaxInstallmentsTax] = useState();
  const [description, setDescription] = useState();
  const [reference, setReference] = useState();
  const [expirateDate, setExpirateDate] = useState();
  const [date, setDate] = useState();
  const [amount, setAmount] = useState({ price: 0 });

  const handleSubmit = async () => {
    try {
      if (amount.price === 0) {
        notification.error({
          message: "Erro",
          description: "Preencha o campo de valor!",
          placement: "topRight",
        });
        return;
      }

      const jsonData = {
        allow_boleto: boleto || false,
        allow_credit_card: credit || false,
        allow_pix: pix || false,
        boleto_due_month_day: 1,
        boleto_max_installments: 1,
        max_installments: maxInstallments,
        max_installments_no_tax: maxInstallmentsTax,
        name,
      };

      const paymentPlanResponse = await apiPaymentCreate(jsonData)
        .then((response) => response)
        .catch((error) => {
          throw error;
        });

      const checkoutPayload = {
        amount: amount.price,
        description,
        name,
        expiration_date: date,
        reference: !reference
          ? name
              .toLowerCase()
              .replace(/\s+/g, "-")
              .replace(/[^\w-]+/g, "")
              .slice(0, 100)
          : reference,
        payment_plan: paymentPlanResponse.id,
      };

      await apiCheckoutCreate(checkoutPayload).catch((error) => {
        throw error;
      });

      notification.success({
        message: "Sucesso",
        description:
          "Link de pagamento criado e checkout realizado com sucesso.",
        placement: "topRight",
      });

      navigate(`/pagamentos`);
    } catch (error) {
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao criar o link de pagamento! Preencha todos os campos de informações do plano de pagamento.",
          placement: "topRight",
        });
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao criar o link de pagamento. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao processar a requisição. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      }
    }
  };

  const handlePixToggle = () => {
    setPix((prevPix) => !prevPix);
  };

  const handleCrediToggle = () => {
    setCredit((prevPix) => !prevPix);
  };

  const handleBoletoToggle = () => {
    setBoleto((prevPix) => !prevPix);
  };

  const handleDateChange = (date, dateString) => {
    setExpirateDate(date);
    setDate(dateString);
  };

  const onChangeInput = (event, nameObject, isNumber) => {
    setAmount({
      [nameObject]: isNumber ? Number(event.target.value) : event.target.value,
    });
  };
  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.name && values.description && values.expirateDate) {
          handleSubmit();
        } else {
          message.error("Preencha todos os campos obrigatórios.");
        }
      })
      .catch(() => {
        message.error("Preencha todos os campos obrigatórios.");
      });
  };

  return (
    <div className="PaymentCreate">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <h2 className="title-page">Criar Pagamento</h2>
          </div>
          <Divider />
          <Breadcrumb.Item>Pagamento</Breadcrumb.Item>
          <Breadcrumb.Item>Criar Pagamento</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">1. Link de Pagamento</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div className="container-left">
              <Form form={form}>
                <Form.Item
                  label="Nome do Link"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o nome do link.",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Item>
                <Form.Item
                  label="Descrição"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a descrição.",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </Form.Item>
                <Form.Item
                  label="Valor"
                  rules={[
                    { required: true, message: "Por favor, insira o valor." },
                  ]}
                >
                  <InputMoney
                    onChange={(e) => onChangeInput(e, "price", true)}
                    value={amount?.price || "0,00"}
                    placeholder="R$ 0,00"
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="container-right">
              <Form form={form}>
                <Form.Item
                  label="Referência"
                  name="reference"
                  rules={[
                    {
                      required: false,
                      message: "Por favor, selecione a data de vencimento.",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setReference(e.target.value)}
                    value={reference}
                  />
                </Form.Item>
                <Form.Item
                  label="Data de Vencimento"
                  name="expirateDate"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione a data de vencimento.",
                    },
                  ]}
                >
                  <DatePicker
                    value={expirateDate}
                    format="YYYY-MM-DD"
                    onChange={handleDateChange}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Content>

        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">2. Plano de Pagamento</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div className="container-left">
              <Card>
                <p className="title">Boleto</p>
                <Divider />
                <Checkbox checked={boleto} onChange={handleBoletoToggle}>
                  Permitir Boleto
                </Checkbox>
                {false && (
                  <>
                    <div>
                      <p>Máximo de parcelas do Cârne</p>
                      <Select
                        placeholder="Selecione um número"
                        onChange={(e) => setMaxBoletonstallments(e)}
                        value={maxBoletonstallments}
                      >
                        {[...Array(12).keys()].map((num) => (
                          <Option key={num + 1} value={num + 1}>
                            x{num + 1}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <p>Data de vencimento (BOLETO)</p>
                      <Input
                        type="number"
                        value={boletoMonthDay}
                        onChange={(e) => setBoletoMothDay(e.target.value)}
                      />
                    </div>
                  </>
                )}
              </Card>
            </div>
            <div className="container-right">
              <Card>
                <p className="title">Cartão</p>
                <Divider />
                <Checkbox checked={credit} onChange={handleCrediToggle}>
                  Permitir Cartão de Crédito
                </Checkbox>
                {credit && (
                  <>
                    <div>
                      <p>Máximo de parcelas</p>
                      <Select
                        placeholder="Selecione um número"
                        onChange={(e) => setMaxInstallments(e)}
                        value={maxInstallments}
                      >
                        {[...Array(12).keys()].map((num) => (
                          <Option key={num + 1} value={num + 1}>
                            x{num + 1}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <p>Máximo de parcelas sem taxa</p>
                      <Select
                        placeholder="Selecione um número"
                        onChange={(e) => setMaxInstallmentsTax(e)}
                        value={maxInstallmentsTax}
                      >
                        <Option key={0} value={0}>
                          Juros ao comprador
                        </Option>
                        {[...Array(12).keys()].map((num) => (
                          <Option key={num + 1} value={num + 1}>
                            x{num + 1}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
              </Card>
              <Card style={{ marginTop: "20px" }}>
                <p className="title">PIX</p>
                <div>
                  <Checkbox checked={pix} onChange={handlePixToggle}>
                    Permitir PIX
                  </Checkbox>
                </div>
              </Card>
            </div>
          </div>
        </Content>

        <div className="container-buttons">
          <Button
            onClick={onFinish}
            type="primary"
          >
            Criar Link de Pagamento
          </Button>
        </div>
      </Layout>
    </div>
  );
};

export default PaymentCreate;

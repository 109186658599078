import { baseSiteEndPoint, buildHeaders, clearStorageExceptSeller } from "./common";

export async function login(username, password) {
  const response = await fetch(`${baseSiteEndPoint}/user/login/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    const error = {
      status: response.status,
      message: errorResponse || response.statusText || response.message,
    };
    throw error;
  }

  const data = await response.json();
  // Armazena os tokens e outras informações no localStorage
  localStorage.setItem("accessToken", data.access);
  localStorage.setItem("refreshToken", data.refresh);
  localStorage.setItem(
    "accessTokenRenewInterval",
    data.access_token_renew_interval
  );
  localStorage.setItem("userInfo", JSON.stringify(data.user));
  return data;
}

export async function getAccess(userID) {
  const response = await authorizedFetch(
    `${baseSiteEndPoint}/user/${userID}/legacy/accesses/`,
    {
      method: "GET",
      headers: buildHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Token refresh failed");
  }
  const data = await response.json();
  return data;
}

export async function apiRefreshLogin(refreshToken) {
  const refresh = localStorage.getItem("refreshToken");
  if (!refresh) {
    throw new Error("No refresh token available");
  }

  const response = await fetch(`${baseSiteEndPoint}/user/login/refresh/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: refresh,
    }),
  });

  if (!response.ok) {
    throw new Error("Token refresh failed");
  }

  const data = await response.json();
  // Atualiza o token de acesso no localStorage
  localStorage.setItem("accessToken", data.access);
  return data;
}

// Função para verificar se o token ainda é válido
export const tokenStillValid = () => {
  if (hasToken()) {
    const expireTime = getItemFromStorage("accessTokenRenewInterval");
    return expireTime > new Date().getTime();
  }
};

function hasToken() {
  return !!localStorage.getItem("accessToken");
}

export const getToken = () => {
  return getItemFromStorage("accessToken");
};

export const getRefreshToken = () => {
  return getItemFromStorage("refreshToken");
};

// Função para obter item do storage
function getItemFromStorage(key) {
  return localStorage.getItem(key);
}

// Função para atualizar o token
function updateToken(data) {
  localStorage.setItem("accessToken", data.access);
  localStorage.setItem("refreshToken", data.refresh);
  localStorage.setItem(
    "accessTokenRenewInterval",
    new Date().getTime() + (data.access_token_renew_interval * 1000)
  );
}

function logoutUser() {
  clearStorageExceptSeller()
}

let refreshPromise = undefined;
export const authorizedFetch = (url, init) => {
  return new Promise((resolve, reject) => {
    if (getItemFromStorage("accessToken")) {
      if (!init.headers) {
        init.headers = {};
      }
      if (!tokenStillValid()) {
        if (!refreshPromise) {
          const refreshToken = getItemFromStorage("refreshToken");
          refreshPromise = apiRefreshLogin(refreshToken);
          refreshPromise
            .then((data) => {
              updateToken(data);
              refreshPromise = undefined;
            })
            .catch(() => {
              clearStorageExceptSeller();
            });
        }
        refreshPromise.then(() => {
          init.headers["Authorization"] = `Bearer ${getToken()}`;
          fetch(url, init).then(resolve, reject);
        });
        return;
      }
      if (hasToken()) {
        init.headers["Authorization"] = `Bearer ${getToken()}`;
      }
    }
    fetch(url, init)
      .then((response) => {
        if (!response.ok) {
          response
            .json()
            .then((errorResponse) => {
              const error = {
                status: response.status,
                message: errorResponse || response.statusText || response.message,
              };

              if (error.status === 401 || error.status === 403) {
                logoutUser();
              }
              reject(error);
            })
            .catch(() => {
              const error = {
                status: response.status,
                message: response.statusText || response.message,
              };

              if (error.status === 401 || error.status === 403) {
                logoutUser();
              }

              reject(error);
            });
          return;
        }
        resolve(response);
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          logoutUser();
        }
        reject(error);
      });
  });
};

import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiAccessList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/user/users`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiAccessDetails = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/user/users/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiAccessCreate = async (jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/user/register/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiAccessEdit = async (id, jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/user/users/${id}/`, {
      method: "PATCH",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiAccessDelete = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/user/users/${id}/`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    return response;
  } catch (error) {
    throw error;
  }
};


// ACCOUNT ACCESS
export const apiAccessAccountList = async (userID, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/user/seller/${userID ? userID : getSellerID()}/users`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiCreateAccessAccount = async (idUser, sellerOption) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/user/${idUser}/legacy/accesses/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify({ seller: sellerOption ? sellerOption : getSellerID() })
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDeleteAccessAccount = async (idUser, idAccess) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/user/${idUser}/legacy/accesses/${idAccess}`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const apiAccessAccounts = async (idUser, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/user/${idUser}/legacy/accesses/`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};